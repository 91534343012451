<template>
    <!--srccomponentsgui_mark_totalgui_mark_totalvuehtmlStart-->

    <div id="defaultId1" class="  u6dd13">
        <div id="ge25b1" class="  u3da49">
            <div class="text_label">
                {{ label }}
            </div>
        </div>
        <el-tooltip :disabled="total.length < 15" :content="total | FilterNum" placement="top">
            <div id="g87d25" class="  u8dd69">{{ total | FilterNum }}
            </div>
        </el-tooltip>
    </div>
    <!--srccomponentsgui_mark_totalgui_mark_totalvuehtmlEnd-->

</template>
<script>
// interfaceCode
//importStart

//importEnd

export default {
    name: '',
    props: {
        // srccomponentsgui_mark_totalgui_mark_totalvuePropsStart

        // 
        label: {
            type: String,
            default: ''
        },
        // 
        total: {
            type: [String, Number],
            default: null
        }
        // srccomponentsgui_mark_totalgui_mark_totalvuePropsEnd


    },
    mounted() {
        // defaultLoad
        // srccomponentsgui_mark_totalgui_mark_totalvueMountedStart

        // srccomponentsgui_mark_totalgui_mark_totalvueMountedEnd


    },
    components: {

    },
    data() {
        return {
            // insertData
            // srccomponentsgui_mark_totalgui_mark_totalvueDataStart

            // srccomponentsgui_mark_totalgui_mark_totalvueDataEnd

        }
    },
    methods: {
        default() {

        },
        // insertMethod
        // srccomponentsgui_mark_totalgui_mark_totalvueMethodStart

        // srccomponentsgui_mark_totalgui_mark_totalvueMethodEnd

        // methodsCode

    },
    computed: {}
}
</script>
<style>
.srccomponentsgui_mark_totalgui_mark_totalvuecssStart {}

.u6dd13 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 8.57em;
    padding: 0.71em 1.42em;
    border-radius: 0.28em;
    border: 1px solid #A2A9B0;
}

.u3da49 {

    margin-bottom: 0.71em;
    text-align: center;
}

.text_label {
    font-size: 1.285714rem;
}

.u8dd69 {
    color: #1290C9;
    font-size: 1.285714rem;
    text-align: center;
    /* line-height: 12px; */
}

@media screen and (max-width: 1441px) {

    .text_label,
    .u8dd69 {
        font-size: 16px;
    }

    .text_label {
        width: max-content;
    }
    .u661e6{
        margin-left:5.5px !important;
        margin-right:5.5px !important;
        padding: 0.7571428rem !important;
    }
  
}

.srccomponentsgui_mark_totalgui_mark_totalvuecssEnd {}


/*cssStart*/

/*cssEnd*/
</style>