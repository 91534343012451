<template>
    <!--srccomponentsgui_collapsegui_collapsevuehtmlStart-->

    <div id="defaultId1">
        <div id="c80d48">
            <div id="c62791" @click="isCollapse= !isCollapse" class="    uc6ecc">
                <svg id="c94fa7" v-show="isHideTab" :class="isCollapse?'':'active'" class="icon    " t="1709789971473" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8252" width="2em" height="1.6em">
                    <path id="c95f70" d="M736 480c-12.5-12.5-32.8-12.5-45.3 0L523.3 647.4c-6.2 6.2-16.4 6.2-22.6 0L333.3 480c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L466.7 704c25 25 65.5 25 90.5 0L736 525.3c12.5-12.5 12.5-32.8 0-45.3z" p-id="8253" fill="#707070">
                    </path>
                </svg>
                <div id="g9834d" v-if="$slots.icon" class="  u40a71">
                    <slot id="cce93d" name="icon">
                    </slot>
                </div>
                <div id="cc6a93" v-show="isHideTab" class="    u9614d" v-if="!$slots.my_title">{{title}}
                </div>
                <slot id="c41dc5" name="my_title">
                </slot>
                <div id="gde6e6" class="  u4373b">
                </div>
            </div>
            <div id="ccb450" v-show="isCollapse&&isHideTab" class="    u57030">
                <slot id="c56abe">
                </slot>
                <div id="g1cba6" class="  u46d22">
                </div>
            </div>
        </div>
    </div>
    <!--srccomponentsgui_collapsegui_collapsevuehtmlEnd-->

</template>
<script>
    // interfaceCode
    //importStart

    //importEnd


    // interfaceCode

    // interfaceCode
    export default {
        name: '',
        props: {
            // 是否展开
            isCollapse: {
                type: Boolean,
                default: true
            },
            // 
            title: {
                type: String,
                default: '标题'
            },
            // 
            // srccomponentsgui_collapsegui_collapsevuePropsStart

            // 是否隐藏所有内容
            isHideTab: {
                type: Boolean,
                default: true
            }
            // srccomponentsgui_collapsegui_collapsevuePropsEnd





        },
        mounted() {
            // defaultLoad
            // srccomponentsgui_collapsegui_collapsevueMountedStart

            // srccomponentsgui_collapsegui_collapsevueMountedEnd

        },
        components: {

        },
        data() {
            return {
                // insertData
                // srccomponentsgui_collapsegui_collapsevueDataStart

                // srccomponentsgui_collapsegui_collapsevueDataEnd

            }
        },
        methods: {
            // insertMethod
            // srccomponentsgui_collapsegui_collapsevueMethodStart
            // default
            default () {

            }
            // srccomponentsgui_collapsegui_collapsevueMethodEnd

        },
        computed: {}
    }
</script>
<style>
    .srccomponentsgui_collapsegui_collapsevuecssStart {}

    .uc6ecc {
        display: flex;
        align-items: center;
        padding-top: 0.5em;
        padding-left: 0.5em;
        padding-right: 0.5em;
        padding-bottom: 0.5em;
    }

    .u40a71 {
        display: none;
        height: 20px;
        margin-right: 0.5em;
    }

    .u9614d {
        font-weight: 400;
        font-size: 1.1428em;
    }

    .u4373b {
        flex: auto;
    }

    .u57030 {
        height: auto;
        padding-top: 0.5em;
        padding-left: 0.5em;
        padding-right: 0.5em;
        padding-bottom: 0.5em;
        ;
    }

    .u46d22 {
        border-bottom-style: solid;
        border-bottom-color: rgba(204, 204, 204, 1);
        border-bottom-width: 1px;
        margin-top: 0.8em;
        margin-left: 0.5em;
        margin-right: 0.5em;
    }

    .uc6ecc .active {
        transform: rotate(-90deg);
    }

    .srccomponentsgui_collapsegui_collapsevuecssEnd {}
</style>