/**
 * 路由与tab菜单
 */
export const tabList = [
  {
    // 详细页面中的tab切换数据，注意 这里的id与路由的id需要一一对应，比如 backgroud中的snapshot模块，则路由名称为backgroundprofile 也无需用驼峰命名直接拼接即可
    name: "Background",
    id: "background",
    children: [
      {
        name: "Snapshot",
        id: "snapshot",
      },
      {
        name: "Profile",
        id: "profile",
      },
      {
        name: "Ownership",
        id: "ownership",
        children: [
          {
            name: "Ultimate Beneficiary",
            id: "Ultimate Beneficiary",
            num: "-",
          },
          {
            name: "Controlling Sharehol",
            id: "Controlling Sharehol",
            num: "-",
          },
          {
            name: "Participation",
            id: "Participation",
            num: "-",
          },
          {
            name: "Involving Individual",
            id: "Involving Individual",
            num: "-",
          },
          {
            name: "Involving Enterprise",
            id: "Involving Enterprise",
            num: "-",
          },
        ],
      },
      {
        name: "Related Companies",
        id: "relatedCompanies",
        children: [
          {
            name: "Total",
            id: "Total",
            num: "-",
          },
          {
            name: "Entities",
            id: "Entities",
            num: "-",
          },
          {
            name: "Persons",
            id: "Persons",
            num: "-",
          },
          {
            name: "Countries",
            id: "Countries",
            num: "-",
          },
          {
            name: "Branches",
            id: "Branches",
            num: "-",
          },
          {
            name: "Related Companies",
            id: "Related Companies",
            num: "-",
          },
        ],
      },
      {
        name: "Management",
        id: "management",
        children: [
          {
            name: "Employees",
            id: "Employees",
            num: "-",
          },
          {
            name: "Contaccts",
            id: "Contaccts",
            num: "-",
          },
          {
            name: "Countries",
            id: "Countries",
            num: "-",
          },
        ],
      },
    ],
  },
  {
    name: "Business Activities",
    id: "businessActivities",
    children: [
      {
        name: "Operations",
        id: "operations",
      },
      {
        name: "Locations",
        id: "locations",
      },
      {
        name: "Industry",
        id: "industry",
      },
      {
        name: "Import & Export",
        id: "import&Export",
        children: [
          {
            name: "Total Import Shipmen",
            id: "Total Import Shipmen",
            num: "-",
          },
          {
            name: "Total Export Shipmen",
            id: "Total Export Shipmen",
            num: "-",
          },
          {
            name: "Max Import Country C",
            id: "Max Import Country C",
            num: "-",
          },
          {
            name: "Max Export Country",
            id: "Max Export Country",
            num: "-",
          },
          {
            name: "Max Import Product",
            id: "Max Import Product",
            num: "-",
          },
          {
            name: "Max Export Product",
            id: "Max Export Product",
            num: "-",
          },
        ],
      },
      {
        name: "Supply Chain",
        id: "supplyChain",
        children: [
          {
            name: "Main Competitor Appl",
            id: "Main Competitor Appl",
            num: "-",
          },
          {
            name: "Main Supplier",
            id: "Main Supplier",
            num: "-",
          },
          {
            name: "Main Customer",
            id: "Main Customer",
            num: "-",
          },
        ],
      },
    ],
  },
  {
    name: "Performance",
    id: "performance",
    children: [
      {
        name: "Financial Highlights",
        id: "financialHighlights",
        children: [
          {
            name: "Revenue",
            id: "Revenue",
            num: "-",
          },
          {
            name: "Profit",
            id: "Profit",
            num: "-",
          },
          {
            name: "Growth",
            id: "Growth",
            num: "-",
          },
          {
            name: "Liquidity",
            id: "Liquidity",
            num: "-",
          },
          {
            name: "Financial Structur",
            id: "Financial Structur",
            num: "-",
          },
          {
            name: "Credit Opinion",
            id: "Credit Opinion",
            num: "-",
          },
          {
            name: "Risk Indicator Lower",
            id: "Risk Indicator Lower",
            num: "-",
          },
          {
            name: "Solvency index(SI)",
            id: "Solvency index(SI)",
            num: "-",
          },
        ],
      },
      {
        name: "Ratio Analysis",
        id: "ratioAnalysis",
        children: [
            {
              name: "Revenue",
              id: "Revenue",
              num: "-",
            },
            {
              name: "Profit",
              id: "Profit",
              num: "-",
            },
            {
              name: "Growth",
              id: "Growth",
              num: "-",
            },
            {
              name: "Liquidity",
              id: "Liquidity",
              num: "-",
            },
            {
              name: "Financial Structur",
              id: "Financial Structur",
              num: "-",
            },
            {
              name: "Credit Opinion",
              id: "Credit Opinion",
              num: "-",
            },
            {
              name: "Risk Indicator Lower",
              id: "Risk Indicator Lower",
              num: "-",
            },
            {
              name: "Solvency index(SI)",
              id: "Solvency index(SI)",
              num: "-",
            },
          ],
      },
      {
        name: "Industry Norms",
        id: "industryNorms",
        children: [
            {
              name: "Revenue",
              id: "Revenue",
              num: "-",
            },
            {
              name: "Profit",
              id: "Profit",
              num: "-",
            },
            {
              name: "Growth",
              id: "Growth",
              num: "-",
            },
            {
              name: "Liquidity",
              id: "Liquidity",
              num: "-",
            },
            {
              name: "Financial Structur",
              id: "Financial Structur",
              num: "-",
            },
            {
              name: "Credit Opinion",
              id: "Credit Opinion",
              num: "-",
            },
            {
              name: "Risk Indicator Lower",
              id: "Risk Indicator Lower",
              num: "-",
            },
            {
              name: "Solvency index(SI)",
              id: "Solvency index(SI)",
              num: "-",
            },
          ],
      },
    ],
  },
  {
    name: "Publications",
    id: "publications",
    children: [
      {
        name: "Lawsuits",
        id: "lawsuits",
        children: [
            {
              name: "Bankruptcy ",
              id: "Bankruptcy ",
              num: "-",
            },
            {
              name: "Litigation Hearing Records",
              id: "Litigation Hearing Records",
              num: "-",
            },
            {
              name: "Record of Arbitratio",
              id: "Record of Arbitratio",
              num: "-",
            },
            {
              name: "Litigation Execution",
              id: "Litigation Execution",
              num: "-",
            },
            {
              name: "Dishonest Debtor",
              id: "Dishonest Debtor",
              num: "-",
            },
            {
              name: "Negative Records",
              id: "Negative Records",
              num: "-",
            },
          ],
      },
      {
        name: "News",
        id: "news",
        children: [
            {
              name: "Bankruptcy ",
              id: "Bankruptcy ",
              num: "-",
            },
            {
              name: "Litigation Hearing Records",
              id: "Litigation Hearing Records",
              num: "-",
            },
      
            {
              name: "Dishonest Debtor",
              id: "Dishonest Debtor",
              num: "-",
            },
            {
              name: "Negative Records",
              id: "Negative Records",
              num: "-",
            },
          ],
      },
      {
        name: "Sanctions",
        id: "sanctions",
        children: [
            {
              name: "Bankruptcy ",
              id: "Bankruptcy ",
              num: "-",
            },
            {
              name: "Litigation Hearing Records",
              id: "Litigation Hearing Records",
              num: "-",
            },
       
            {
              name: "Dishonest Debtor",
              id: "Dishonest Debtor",
              num: "-",
            },
            {
              name: "Negative Records",
              id: "Negative Records",
              num: "-",
            },
          ],
      },
    ],
  },
  {
    name: "Credit Opinion",
    id: "creditOpinion",
    isNoShowTab2:'noShowAll', // 不显示tab2
    children: [{
        name:'tab2',
        id:'list',
    }],
  },
  {
    name: "Market Analysis",
    id: "marketAnalysis",
    isNoShowTab2:'showMark', // 只显示卡片
    children:  [{
        name:'tab2',
        id:'list',
        children: [
            {
              name: "Country",
              id: "Country",
              num: "-",
            },
            {
              name: "Industry",
              id: "Industry",
              num: "-",
            },
            {
              name: "Size",
              id: "Size",
              num: "-",
            },
            {
              name: "Liquidity",
              id: "Liquidity",
              num: "-",
            },
            {
              name: "Company Nature",
              id: "Company Nature",
              num: "-",
            },
            {
              name: "Number of Industry",
              id: "Number of Industry",
              num: "-",
            },
            {
                name: "Industry Rating",
                id: "Industry Rating",
                num: "-",
              },
              {
                name: "Performance Comparis",
                id: "Performance Comparis",
                num: "-",
              },
              {
                name: "Attribute Comparison",
                id: "Attribute Comparison",
                num: "-",
              },
              {
                name: "Rating Comparison",
                id: "Rating Comparison",
                num: "-",
              },
          ],
    }],
  },
];
let routes = {};
tabList.forEach((item) => {
  item.children.forEach((item2) => {
    let obj = {
      tab1: item.id,
      tab2: item2.id,
      isNoShowTab2:item.isNoShowTab2,
      tab2List: item.children,
    };
    routes[item.id + item2.id] = obj;
  });
});
export const menuRouter = routes;

export const themeColor = { // 主题颜色配置
    company:'#1290C9', // 搜索公司主题
    product:'#8E86DD',  // 搜索产品
    trade:'#009EA7',
    risk:'#8E86DD',
    country:'#F283BF',
    news:'#009EA7'
}
