<template>
    <!--srccomponentsgui_checkboxgui_checkboxvuehtmlStart-->

    <div id="defaultId1">
        <div id="c22d3d" @click="change()" class="   u414cd">
            <div id="ge0551" v-if="isCircle" class="  uade4f">
                <div id="g0c122" v-show="value" class="  u85694">
                </div>
            </div>
            <div id="c3a486" v-if="!isCircle" class="   u1d92c">
                <div id="gbecd4" v-show="!value" class="   u6bd15">
                </div>
                <div id="ce4725" v-show="value" :style="{     'background-color':$store.state.app.themeColor }" class="  u6bd15   uab79a">
                    <svg id="idK9hncN" class="icon  " t="1717127899731" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6121" width="1.1em" height="1.1em">
                        <path id="idFAzEil" d="M939.36 218.912a32 32 0 0 1 45.856 44.672l-538.016 552a32 32 0 0 1-43.776 1.92L63.872 526.048a32 32 0 1 1 41.696-48.544l316.768 271.936L939.36 218.88z" fill="#FFF" p-id="6122">
                        </path>
                    </svg>
                </div>
            </div>
            <div id="c97735" :style="value?'color:' + $store.state.app.themeColor:''" class="   ub86d5">{{title}}
            </div>
            <div id="c92876" v-if="isShowTotal" class="   u6b134">{{total}}
            </div>
            <div id="gb39c7" class="   u632d0" v-if="false">
                <svg id="c59868" v-show="value" class="icon  " t="1709796180631" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9722" width="1.4285em" height="1.4285em">
                    <path id="c4904b" d="M813.056 107.008H204.8a102.4 102.4 0 0 0-102.4 102.4V819.2a102.4 102.4 0 0 0 102.4 102.4h609.28a102.4 102.4 0 0 0 102.4-102.4V208.896a102.4 102.4 0 0 0-103.424-101.888z m-31.744 240.128L413.696 716.8a25.6 25.6 0 0 1-17.92 7.68 25.6 25.6 0 0 1-18.944-7.68L235.008 556.544a25.6 25.6 0 1 1 37.888-33.792L396.288 660.48l349.184-349.184a25.6 25.6 0 1 1 35.84 35.84z" p-id="9723" fill="#1196db">
                    </path>
                </svg>
                <svg id="c1c27b" v-show="!value" class="icon  " t="1709796133933" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9424" width="1.4285em" height="1.4285em">
                    <path id="c7c7ba" d="M768 128 256 128C185.6 128 128 185.6 128 256l0 512c0 70.4 57.6 128 128 128l512 0c70.4 0 128-57.6 128-128L896 256C896 185.6 838.4 128 768 128zM832 768c0 35.2-28.8 64-64 64L256 832c-35.2 0-64-28.8-64-64L192 256c0-35.2 28.8-64 64-64l512 0c35.2 0 64 28.8 64 64L832 768z" p-id="9425" fill="#8497AB">
                    </path>
                </svg>
            </div>
        </div>
    </div>
    <!--srccomponentsgui_checkboxgui_checkboxvuehtmlEnd-->

</template>
<script>
    // interfaceCode
    //importStart

    //importEnd

    export default {
        name: "",
        props: {
            // srccomponentsgui_checkboxgui_checkboxvuePropsStart

            // 圆形图标
            isCircle: {
                type: Boolean,
                default: false
            },
            // value
            value: {
                type: Boolean,
                default: false
            },
            // title
            title: {
                type: String,
                default: '标题'
            },
            // total
            total: {
                type: String,
                default: ''
            },
            // 显示总数
            isShowTotal: {
                type: Boolean,
                default: true
            }
            // srccomponentsgui_checkboxgui_checkboxvuePropsEnd


        },
        mounted() {
            // defaultLoad
            // srccomponentsgui_checkboxgui_checkboxvueMountedStart

            // srccomponentsgui_checkboxgui_checkboxvueMountedEnd

        },
        components: {},
        data() {
            return {
                // insertData
                // srccomponentsgui_checkboxgui_checkboxvueDataStart

                // srccomponentsgui_checkboxgui_checkboxvueDataEnd

            }
        },
        methods: {
            // insertMethod
            // srccomponentsgui_checkboxgui_checkboxvueMethodStart
            // default
            default () {

            }, // change
            change() {

                this.value = !this.value;
                this.$emit("input", this.value);

            }
            // srccomponentsgui_checkboxgui_checkboxvueMethodEnd

        },
        computed: {}
    };
</script>
<style>
    .srccomponentsgui_checkboxgui_checkboxvuecssStart {}

    .u414cd {
        display: flex;
        align-items: center;
    }

    .uade4f {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border-top-left-radius: 1.3em;
        border-bottom-left-radius: 1.3em;
        border-top-right-radius: 1.3em;
        border-bottom-right-radius: 1.3em;
        background: #FFFFFF;
        border: 1px solid #685ED1;
        ;
    }

    .u85694 {
        width: 12px;
        height: 12px;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        background-color: rgba(104, 94, 209, 1);
    }

    .u1d92c {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .u6bd15 {
        width: 1.3em;
        height: 1.3em;
        border-width: 1px;
        border-color: rgb(153, 153, 153);
        border-style: solid;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .uab79a {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ub86d5 {
        font-size: 1.1428em;
        margin-left: 0.5em;
        flex: auto;
        color: rgb(67, 66, 66);
    }

    .u6b134 {
        font-size: 1.1428em;
        color: rgb(132, 151, 171);
    }

    .u632d0 {
        height: 20px;
    }

    .srccomponentsgui_checkboxgui_checkboxvuecssEnd {}
</style>