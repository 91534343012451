<template>
    <div>
        <el-dialog :visible.sync="newDialog" center :close-on-click-modal="true" @close="closeDialog"
            custom-class="dialog_normalsuo" :append-to-body="true">
            <div>
                <div class="detail_cont">
                    <h3>Update</h3>
                    <div class="detail_cont_text" style="padding: 0px;">
                        Financial performance is a paid feature, this allows you to view all the available data
                        including financial details and credit assessment on the company. The cost of this update is 30
                        units per company.
                        <br />
                        Subject company's financial performance data is often collected from various public data sources
                        as well as local credit reporting agencies. This data is also calculated, modeled and forecasted
                        based on market and industry research and a variety of available data. This data is for your
                        reference only.
                        <br />
                        If the available online data is incomplete or out of date, an update for a fresh investigated
                        business credit report will be automatically ordered via our local credit reporting agencies. It
                        normally takes about 72 hours for an update report delivery. A business credit report often
                        contains details about the subject company's actual operations, financial performance, credit
                        analysis and assessment.
                    </div>

                </div>
            </div>
            <div class="flex_center" style="margin-top: 20px;">
                <el-button class="btn_export btn_exportCCC" @click="onSubmitkoudian()">OK</el-button>
                <el-button class="btn_export" @click="onSubmitquxiao()">Add Credits</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { saveUserConsumption, } from "@/api/userApi";

export default {
    props: {
        suoClose: {
            type: Boolean,
            //   default: false,
        },
        obj: {
            type: Object,
        },
        update: {
            type: Object,
        },
        ComapnyName:{
            type:String
        }
    },
    data() {
        return {
            //page: this.currentPage

            token: sessionStorage.getItem('token'),
        };
    },
    computed: {
        newDialog: {
            get() {
                return this.suoClose;
            },
            set() { },
        },

    },
    destroyed() {
        // bus.$off('handleShowLogin');
    },
    methods: {
        //关闭弹窗
        closeDialog() {
            this.$emit('closeCloseDialog', false);
        },
        onSubmitkoudian() {
            this.newDialog = false;
            this.$emit('closeCloseDialog', false);
            const base = JSON.parse(localStorage.getItem("CompanyBase"));
            
            saveUserConsumption({
                companyAaaid: this.$route.query.id3a,
                countryCode:  base.base.countryFlag,
                companyName:this.ComapnyName
            }).then(res => {
                if (res.code == 200) {
            this.$parent.toolsConfig.update.isActive = false
            this.$router.go(0)
            this.$parent.UpdateStatus=3
                    this.$message({
                        message: '-1',
                        type: 'success'
                    })
                } else {
                    this.$message.error(res.msg);
                }
            })
            //  this.$emit('closeCloseDialog1', true);


        },
        onSubmitquxiao() {
      this.$router.push({
        path: '/index/addCredit',
      });
            // this.newDialog = false;
            // this.$emit('closeCloseDialog', false);
            let that = this;
            let userinfo = JSON.parse(localStorage.getItem('userInfo')).userInfo;


        },
    },
};
</script>
<style scoped>
.detail_cont {
    max-height: 85vh;
    overflow: auto;

}



/* .detail_cont_text {
    font-size: 18px;
    color: #1A2332;
    line-height: 30px;
    padding: 20px 0px;
    margin-top: 0px;
} */


</style>
