<template>
    <!--srccomponentsgui_link_contentgui_link_contentvuehtmlStart-->

    <div id="defaultId1">
        <el-link id="ce16cf" @click="openHangyefenxi()" type="primary">{{linkTitle}}
        </el-link>
        <div id="c2ed5f" v-show="ishangyefenxi" class="  ucbe03">{{hangyefenxiContent}}
        </div>
    </div>
    <!--srccomponentsgui_link_contentgui_link_contentvuehtmlEnd-->

</template>
<script>
    // interfaceCode
    //importStart

    //importEnd

    export default {
        name: '',
        props: {
            // srccomponentsgui_link_contentgui_link_contentvuePropsStart

            // 标题
            linkTitle: {
                type: String,
                default: '行业分析'
            },
            // 分析结果
            result: {
                type: String,
                default: ''
            }
            // srccomponentsgui_link_contentgui_link_contentvuePropsEnd


        },
        mounted() {
            // defaultLoad
            // srccomponentsgui_link_contentgui_link_contentvueMountedStart

            // srccomponentsgui_link_contentgui_link_contentvueMountedEnd


        },
        components: {

        },
        data() {
            return {
                // insertData
                // srccomponentsgui_link_contentgui_link_contentvueDataStart

                // 行业分析
                ishangyefenxi: false,
                // 行业分析内容
                hangyefenxiContent: '分析内容'
                // srccomponentsgui_link_contentgui_link_contentvueDataEnd

            }
        },
        methods: {
            default () {

            },
            // insertMethod
            // srccomponentsgui_link_contentgui_link_contentvueMethodStart
            // 打开行业分析
            openHangyefenxi() {
                if (!this.ishangyefenxi) {
                    this.hangyefenxiContent = '正在分析中。。。'
                    setTimeout(() => {
                        this.hangyefenxiContent = this.result
                        // debugger
                        // 注意：这里由于内容增加了高度变化了，导致布局超出了界面，所以需要隐藏之后再重新展示，让其自动计算位置。
                        this.$emit('click_link', true)

                    }, 400)
                } else {
                    //this.$refs.myPopover.doShow()
                }
                this.ishangyefenxi = !this.ishangyefenxi



            }
            // srccomponentsgui_link_contentgui_link_contentvueMethodEnd

            // methodsCode

        },
        computed: {}
    }
</script>
<style>
    .srccomponentsgui_link_contentgui_link_contentvuecssStart {}

    .ucbe03 {
        overflow-y: auto;
    }

    .srccomponentsgui_link_contentgui_link_contentvuecssEnd {}


    /*cssStart*/

    /*cssEnd*/
</style>