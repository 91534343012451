<template>
    <!--srcviewslayoutcontent_viewvuehtmlStart-->
    <!-- @mousedown="handleonmousedown($event)" -->
    <div id="defaultId1" class="     uJqLc8N">
        <div id="cb0e1d" class="      u57a4a" >
            <!-- 工具栏 -->
            <div id="c0437a" class="      u7edc6">
                <slot id="c7e41c" name="header">
                </slot>
            </div>
            <!-- 搜索label  -->
            <div id="g16fa7" >
                <slot id="c77f7d" v-if="$store.state.app.searchType == 'company'" name="remark">
                </slot>
                <slot id="c77f7d" v-if="$store.state.app.searchType == 'risk'" name="leads">
                </slot>
            </div>
            <div id="ccd3b6" class="      uad1e5" v-if="!$slots.details_view">
                <!--统一left -->
                <!-- $slots.left_view2 &&  -->
                <!-- <div id="c880c9" :class="$store.state.app.isMenu?'':'hidemenu'" class="  scrollbar     u7d40b" v-if="$route.name=='riskNoParams'">
                    <slot id="cd68cb" name="left_view2">
                    </slot>
                </div> -->
                <div id="ce5c28" class="  u0509e u7d40b      u07452" v-if="$slots.left_view1">
                    <slot id="cf6743" class="  uNqsp2S      " name="left_view1">
                    </slot>
                </div>
                <div id="ce624f" class=" u7d40b       uc6b92" v-if="$slots.content_view">
                    <slot id="c548fb" name="content_view">
                    </slot>
                </div>
                <!-- <div id="cded8d" class=" u7d40b      u0509e" v-if="$slots.right_view">
                    <slot id="c20d02" name="right_view">
                    </slot>
                </div> -->
            </div>
            <div id="g2a26b" class="    u14ed3" v-if="$slots.details_view">
                <slot id="cf21b6" name="details_view">
                </slot>
            </div>
        </div>
    </div>
    <!--srcviewslayoutcontent_viewvuehtmlEnd-->

</template>
<script>
    // interfaceCode
    //importStart

    //importEnd

    export default {
        name: "",
        props: {
            // srcviewslayoutcontent_viewvuePropsStart

            // srcviewslayoutcontent_viewvuePropsEnd


        },
        updated() {
        window.addEventListener('setItem', () => {
            this.token = localStorage.getItem('token');
        });
    },
    destroyed() {
        window.removeEventListener('setItem', () => {
            this.token = localStorage.getItem('token');
        });
    },
    watch: {
        // token: {
        //     handler(newVal, oldVal) {
        //         if (newVal) {
        //             this.handleonmousedown()
        //             // this.openMenu = ['2', '3', '4', '5', '6', '7', '8'];
                  
        //         }
        //     },
        //     immediate: true,
        // },
   
    },
        mounted() {
            // defaultLoad
            // srcviewslayoutcontent_viewvueMountedStart

            // srcviewslayoutcontent_viewvueMountedEnd

        },
        components: {},
        data() {
            return {
                // insertData
                // srcviewslayoutcontent_viewvueDataStart

                // isMenu
                token: localStorage.getItem('token'),
                isMenu: true
                // srcviewslayoutcontent_viewvueDataEnd

            }
        },
        methods: {
                // 处理鼠标点击事件 登录权限
    handleonmousedown(e) {
      // 处理登录功能：
      // 未登录  允许点击【翻译】和【详情】
      const token = localStorage.getItem('token');
      if (!token) {
        // if (!(e.target?._prevClass === 'no_login' || e.target._prevClass === 'tbl_company line_1')) {
        // bus.$emit('headLogFlg', true); //是否显示登录
        this.$message.error('Please log in to the homepage');
        setTimeout(()=>{
       this.$router.push('/#/')
           
        },1000)
     
        bus.$emit('handleShowLogin', true); //是否显示登录
        // }
      }
    },
            // insertMethod
            // srcviewslayoutcontent_viewvueMethodStart
            // default
            default () {

            }, // changeMenu
            changeMenu(isMenu) {

                this.isMenu = isMenu;

            }
            // srcviewslayoutcontent_viewvueMethodEnd

        },
        computed: {}
    };
</script>
<style>
    .srcviewslayoutcontent_viewvuecssStart {}

    .uJqLc8N {
        width: 100%;
        height: 100%;
    }

    .u57a4a {
        display: flex;
        width: 100%;
        height: 100%;
        background-color: rgb(245, 245, 245);
        flex: none;
        box-sizing: border-box;
        flex-direction: column;
    }

    .u7edc6 {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        height: 4rem;
        background-color: #FFFFFF;
        position: fixed;
        z-index: 999;
        width: 100%;
        box-shadow: 0px 2px 4px 0px rgba(109,133,144,0.3);
        /* overflow-y: hidden;
        overflow-x: scroll; */
    }

    .uad1e5 {
        display: flex;
        height: 0%;
        flex: auto;
        box-sizing: border-box;
    }

    .u7d40b {
        /* width: 24.64em; */
 
        /* box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden; */
        transition: 0.2s;
    }

    .u07452 {
        overflow-y: auto;
    }

    .uc6b92 {
        width: 0%;
        flex: auto;

    }

    .u0509e {
        width: 17.3em;
        margin-bottom: 0.5rem;
        margin-left: 0.7rem;
        box-shadow: 0px 1px 1px 2px rgb(228, 226, 226);
        border-top-left-radius: 0.2rem;
        border-bottom-left-radius: 0.2rem;
        border-top-right-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
        background-color: rgba(255, 255, 255, 0.56);
    }

    .u14ed3 {
        height: 0;
        flex: auto;
        /* overflow-y: auto; */
    }

    .uad1e5 .hidemenu {
        display: block;
        width: 0rem;
        padding-left: 0rem;
        padding-top: 0rem;
        padding-bottom: 0rem;
        padding-right: 0rem;
        box-sizing: border-box;
        overflow-y: hidden;
        overflow-x: hidden;
    }

    .srcviewslayoutcontent_viewvuecssEnd {}
</style>