function isEqual(obj1, obj2) {
    return obj1.key === obj2.key // 只需判断key是否相同即可 obj1.title === obj2.title && obj1.key === obj2.key && obj1.width === obj2.width;
}
/**
 * 根据数组进行自动排序
 * @param {*} defaultArr 默认数据
 * @param {*} currentArr 当前数据
 * @param {*} addArr 新加的数据
 */
export function autoSortArr(defaultArr,currentArr,addArr){
    let allArr = defaultArr.concat(addArr.filter(item => !defaultArr.some(existingItem => isEqual(existingItem, item)))); // 所有的所选的非重复的元素获取下来
    console.log(allArr)
    let newArr = []
    currentArr.forEach((item,index)=>{ // 当前数据进行循环排序
       let obj = allArr.find(item2=>item2.key == item.key)
       if(obj){
            newArr.push(obj)
       }
    })
    let result = newArr.concat(allArr.filter(item => !newArr.some(existingItem => isEqual(existingItem, item))));
    return result
}